import { createTheme } from "@mui/material";

export const theme = createTheme({
  
    palette: {
  
      background: {
  
  
  
      },
  
    },
  
    typography: {
  
      fontFamily: "Raleway"
  
    }
  
}); 