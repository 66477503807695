import { ListItem, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import { WeighedResult } from "../../../hooks/UseWeighedScores";
import { RatingIcon } from "../RatingIcon";




export const ResultSummary = (props: {result : WeighedResult | undefined, index : number, onShowOriginal: (index : number) => void, onRerun : (index : number) => void}) => {
    
    const { result, index, onRerun, onShowOriginal } = props

    const name = useMemo(() => {

        if(result?.applicant.name === "null") {

            return undefined

        }

        return result?.applicant.name

    }, [result?.applicant.name])

    const { palette } = useTheme()


    if(!result) {

        return (
            <Stack
                position={"fixed"}
                top={"6rem"}
                mx={"2rem"}
                p={"2rem"}
                height={"80%"}
                width={"40%"}
                bottom={"2rem"}
                boxShadow={6}
                left={"57.5%"}
                overflow={"auto"}
                sx={{
                    backgroundColor: palette.background.paper
                }}
            >
                
                <Typography
                    variant="h3"
                >

                    Sorry, this resume result couldn't be created!

                </Typography>

                <Button
                    variant="contained"
                    size="large"
                    onMouseDown={() => onRerun(index)}
                >
                    <Typography
                        variant="h5"
                    >
                        Attempt Re-run?
                    </Typography>

                </Button>

            </Stack>
        )
        
    }

    return (

        <Stack
            position={"fixed"}
            top={"6rem"}
            mx={"2rem"}
            p={"2rem"}
            minHeight={"80%"}
            bottom={"2rem"}
            boxShadow={6}
            left={"57.5%"}
            overflow={"auto"}
            sx={{
                backgroundColor: palette.background.paper
            }}
        >
            
            <Stack
                direction={"row"}
                gap={"1rem"}
            >

                <Typography
                    variant="h4"
                >
                    Name:
                </Typography>
                <Typography
                    variant="h4"
                >
                    {name || "Not Found"}
                </Typography>

            </Stack>

            <Box
                alignSelf={"center"}
            >

                <RatingIcon
                    grade={result.grade}
                    large
                />

            </Box>

            <Button
                variant="text"
                color="secondary"
                size="large"
                sx={{
                    mb: "rem"
                }}
                onMouseDown={() => {

                    onShowOriginal(index)

                }}
            >
                <Typography
                    textAlign={"center"}
                    variant="subtitle1"
                >
                    View Original Resume
                </Typography>
            </Button>


            <Stack
                direction={"row"}
                gap={"1rem"}
            >

                <Typography
                    variant="h5"
                >
                    Overall Score:
                </Typography>
                <Typography
                    variant="h5"
                >
                    {Math.round(result.overall*100)/100 || "0"}
                </Typography>

            </Stack>

            <Divider
                sx={{
                    m: "1rem",
                    mb: "1.6rem"
                }}
            />

            <List>
                
                <ListItem
                    key={result._id + Math.random()}
                >

                    <Typography
                        variant="h5"
                    >
                        Section Summaries:
                    </Typography>

                </ListItem>

            </List>


            <List>

            { result.summaries.map(summary => {

                return (
                    <ListItem
                        key={result._id + Math.random()}
                    >

                        <Stack
                            width={"100%"}
                        >
                            <Typography
                                variant="h6"
                            >
                                {summary.section}
                            </Typography>

                            <Divider
                                sx={{
                                    m: "0.5rem",
                                    mr: "15rem"
                                }}
                            />

                            <Typography
                                variant="subtitle1"
                            >
                                {summary.summary}
                            </Typography>

                        </Stack>

                    </ListItem>

                )

                })}

            </List>

            <Divider
                sx={{
                    m: "1rem"
                }}
            />

            <Typography
                variant="h5"
            >
                Score Breakdown:
            </Typography>

            <List>

            { result.filterScores.map(score => {

                return (
                    <ListItem
                        key={result._id + Math.random()}
                    >

                        <Stack
                            width={"100%"}
                        >

                            <Stack
                                direction={"column"}
                                alignItems={"center"}
                            >

                                <Typography
                                    variant="h6"
                                    textAlign={"center"}
                                >
                                    {score.filter}:
                                </Typography>

                                <Typography
                                    variant="h3"
                                    textAlign={"center"}
                                >
                                    {score.value}
                                </Typography>

                            </Stack>

                            <Typography
                                variant="subtitle1"
                            >
                                {score.rationale}
                            </Typography>

                        </Stack>

                    </ListItem>

                )

                })}

            </List>


        </Stack>

    )


}